<template>
  <div class="mainRoot">
    <v-app-bar flat class="header">
      <v-icon color="accent" size="45">$vuetify.icons.logo</v-icon>
    </v-app-bar>

    <v-container fluid class="content" pa-5>
      <v-row v-if="!$store.state.flavorStatic" ma-0>
        <v-col>
          <div class="black--text settingsCategory">{{ $t("service") }}</div>
        </v-col>
      </v-row>
      <v-row v-if="!$store.state.flavorStatic" class="ma-0">
        <v-col class="language-item">
          <v-radio-group
            v-model="service"
            v-if="$store.state.flavorsAvailable != null"
          >
            <v-radio
              v-for="service in servicesAvailable"
              :key="service.value"
              :label="service.name"
              :value="service.value"
              :class="service.value"
              :style="styleForService(service.value)"
            ></v-radio>
          </v-radio-group>
          <v-radio-group v-model="service" v-else>
            <v-radio
              v-for="service in services"
              :key="service.value"
              :label="service.name"
              :value="service.value"
              :class="service.value"
              :style="styleForService(service.value)"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="black--text settingsCategory">{{ $t("font_size") }}</div>
        </v-col>
      </v-row>
      <v-row class="pa-2">
        <v-col>
          <v-slider
            v-model="textSizeAdjustment"
            min="-100"
            max="100"
            step="25"
            ticks="always"
            tick-size="5"
          >
            <template v-slot:prepend>
              <span class="settingsSmallFontA">A</span>
            </template>

            <template v-slot:append>
              <span class="settingsLargeFontA">A</span>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row class="pa-2" v-if="contactEmail">
        <v-col>
          <div class="contact-email-title">{{ $t("email") }}</div>
          <div class="body-2  contact-email-value">
            {{ contactEmail }}
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-2" v-if="contactPhone">
        <v-col>
          <div class=" contact-phone-title">
            {{ $t("phone") }}
          </div>
          <div class="body-2 contact-phone-value">
            {{ contactPhone }}
          </div>
        </v-col>
      </v-row>
      <v-row class="pa-2" v-if="contactWeb">
        <v-col>
          <div class="contact-web-title">{{ $t("contact_us") }}</div>
          <div class=" body-2 contact-web-value">
            {{ contactWeb }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="appVersionText">{{ $root.versionString }}</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import flavors from "../config"
  import fontHelper from "../services/fonthelper"

  export default {
    name: "More",
    activated() {
      var fonts = {}
      for (var flavor of Object.keys(flavors)) {
        if (flavors[flavor].fonts) {
          fonts = Object.assign(fonts, flavors[flavor].fonts)
        }
      }
      fontHelper.generateFontFaceImports(fonts, "fontsTemp")
    },
    deactivated() {
      // Remove
      fontHelper.generateFontFaceImports(null, "fontsTemp")
    },
    methods: {
      styleForService(svc) {
        const service = flavors[svc]
        return fontHelper.styleForFonts(service.fonts)
      },
    },
    computed: {
      services() {
        return Object.keys(flavors).map(function(key) {
          return {name: flavors[key].name, value: key}
        })
      },

      servicesAvailable() {
        let available = this.$store.state.flavorsAvailable
        if (available) {
          let rgAvailable = available.split(",")
          return Object.keys(flavors)
            .filter(function(key) {
              return rgAvailable.includes(key)
            })
            .map(function(key) {
              return {name: flavors[key].name, value: key}
            })
        }
        return null
      },

      service: {
        get() {
          let flavor = this.$store.state.flavor
          if (flavors[flavor]) {
            return flavor // Valid value
          }
          return "english"
        },
        set(val) {
          this.$logger.logLanguageSelect(val, true)
          this.$store.commit("setFlavor", val)
        },
      },

      textSizeAdjustment: {
        get() {
          return this.$store.state.textSizeAdjustment
        },
        set(val) {
          this.$store.commit("setTextSizeAdjustment", val)
          this.$logger.logTextSizeSelect(val)
        },
      },

      contactEmail() {
        return flavors[this.service].contactEmail
      },

      contactPhone() {
        return flavors[this.service].contactPhone
      },

      contactWeb() {
        return flavors[this.service].contactWeb
      },
    },

    // watch: {
    //   $route: function (to, ignoredFrom) {
    //     if (this.thisRouteName == to.name) {
    //       console.log("More Shown");
    //     }
    //   },
    // },
  }
</script>

<style scoped>
  @import url("../assets/css/item-style.css");
  @import url("../assets/css/shared-styles.css");

  .mainRoot {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: absolute;
  }
  .header {
    height: var(--v-header-size);
    background-color: #fff !important;
  }

  .content {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
</style>
